module.exports = {


  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,

  //上线域名与站点对应配置
  siteMapDomain: [{
    sitePath: '/wuxi/index',
    domain: 'wuxi.chinatupai.com'
  },
  {
    sitePath: '/suzhou/index',
    domain: 'suzhou.chinatupai.com'
  },
  {
    sitePath: '/changzhou/index',
    domain: 'changzhou.chinatupai.com'
  },
  {
    sitePath: '/nanjing/index',
    domain: 'nanjing.chinatupai.com'
  },
  {
    sitePath: '/nantong/index',
    domain: 'nantong.chinatupai.com'
  },
  {
    sitePath: '/nanchang/index',
    domain: 'nanchang.chinatupai.com'
  },
  {
    sitePath: '/hefei/index',
    domain: 'hefei.chinatupai.com'
  },
  {
    sitePath: '/hangzhou/index',
    domain: 'hangzhou.chinatupai.com'
  }
],


title: '土拍网-土地投资门户',


  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,

  /**
   * leaflet 地图配置
   */ 
 

    //  leafletmapurl: 'https://webrd04.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}',
     isgaode:2,//1 高德地图  2：天地图
  //  leafletmapurl:'https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png',
  //tianditu vec_w /img_w
  // leafletmapurl:'https://t0.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=defalut&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0d330c975555186af36623a21c04bd71',


  leafletmapurl:'http://t0.tianditu.gov.cn/vec_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=vec&tileMatrixSet=c&TileMatrix={z}&TileRow={y}&TileCol={x}&style=default&format=tiles&tk=0d330c975555186af36623a21c04bd71',
    //矢量注记
  leafletmaptexturl:'http://t0.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0d330c975555186af36623a21c04bd71',
     //影像注记
  leafletmapyingxiangurl:'http://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0d330c975555186af36623a21c04bd71',
     
  //地形晕渲
  // leafletmapyunxuanurl:'http://t0.tianditu.gov.cn/ter_w/wmts?tk=0d330c975555186af36623a21c04bd71',

  vectorMapLayer:{
    url: 'http://t0.tianditu.gov.cn/vec_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=vec&tileMatrixSet=c&TileMatrix={z}&TileRow={y}&TileCol={x}&style=default&format=tiles&tk=0d330c975555186af36623a21c04bd71',
    texturl: 'http://t0.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0d330c975555186af36623a21c04bd71',
    attribution: '&copy; OpenStreetMap contributors',
  },

  satelliteMapLayer:{
    url: 'http://t0.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=c&TileMatrix={z}&TileRow={y}&TileCol={x}&style=default&format=tiles&tk=0d330c975555186af36623a21c04bd71',
    texturl: 'http://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=0d330c975555186af36623a21c04bd71',
    attribution: '&copy; OpenStreetMap contributors',
  },
}
